<template>
  <template v-if="set">
    <tr @click.prevent.stop="onClick" class="small">
      <td class="font-monospace text-end">{{ set.model }}</td>
      <td class="ellipsis"><div :title=" escapedTitle ">{{ set.title }}</div></td>
      <td class="text-center">
        <span class="badge rounded-pill bg-secondary">{{ set.sets.length }}</span>
      </td>
      <td class="font-monospace text-end">
        ${{ set.sum.toFixed(2) }}
      </td>
    </tr>
  </template>
</template>

<script>

export default {
  name: "BrickCollectionRow",
  props: ['set'],
  data:() => ({
    editPage: false
  }),
  computed: {
    escapedTitle() {
      return this.set.title.replace('"', '')
    }
  },
  methods: {
    onClick() {
      this.$emit('onClick', this.set)
    }
  }
}
</script>
<style lang="scss">
.expandable {
  overflow: hidden;
  height: auto;
  max-height: 50px;
  cursor: pointer;
  transition: maxHeight 0.25s ease-in-out;
  &:hover {
    overflow-y: scroll;
  }
}
</style>
