<template>
  <div class="wrapper">
    <h1>Password Reset</h1>
    <div class="mb-5 border-bottom">
      <div v-if="errorMessage" @click="errorMessage=''" class="alert alert-info">
        {{ errorMessage }}
      </div>
      <form v-else @submit.prevent="sendRequest">
        <div class="mb-3">
          <input type="email" placeholder="Email" v-model="email" class="form-control" />
        </div>
        <div class="mb-3">
          <button :disabled="disabled" class="btn btn-primary">Send Reset Password Email</button>
        </div>
      </form>
    </div>
    <div class="d-flex justify-content-center flex-wrap">
      <router-link :to="{name:'Login'}" class="btn btn-link">Login</router-link>
    </div>
  </div>
</template>
<script>
import firebase from 'firebase'
export default  {
  name: "PasswordReset",
  data() {
    return {
      email: '',
      errorMessage: ''
    }
  },
  computed: {
    disabled() {
      return !this.email || this.email.search(/^[\w+_.-]+@[\w_-]{2,}\.[\w+_.-]{2,}/) < 0
    }
  },
  methods: {
    sendRequest() {
      firebase.auth().sendPasswordResetEmail(this.email,{
        url: `${document.location.origin}/auth/login?email=${this.email}`,
        handleCodeInApp: true
      })
      .then(() => {
        this.errorMessage = 'Email has been sent.  Please check your email inbox.'
      })
      .catch(err => {
        console.log(err)
        this.errorMessage = err.message
      })
    }
  }
}
</script>
