<template>
  <div class="wrapper">
    <h1 class="display-6">
      Lego™ Brick Sets Collection
    </h1>

    <div class="my-3 sticky-top">
      <brick-collection-add />
    </div>

    <transition name="fade">
      <table v-if="!currentSet" class="table table-hover table-striped table-responsive table-sm small table-bordered">
        <thead>
          <tr>
            <th @click="sortByModel">Model</th>
            <th @click="sortByName">Set Title</th>
            <th><span class="badge bg-warning text-dark rounded-pill">{{ totalCollections }}</span></th>
            <th class="text-end" @click="sortByValue"><span class="badge bg-success rounded-pill">${{ totalValue }}</span></th>
          </tr>
        </thead>
        <tbody v-if="loaded">
          <template v-if="brickCollection && brickCollection.length">
            <brick-collection-row :set="set" v-for="set in collectionSorted"
                                  @onClick="setCurrentSet"
                                  :key="set.id"/>
          </template>
          <template v-else>
            <tr><td colspan="3" class="p-5 bg-light text-center">
              <strong>Empty Collection.</strong>
              <div class="small">Start by looking up UPC for your sets.</div>
            </td></tr>
          </template>
        </tbody>
      </table>
    </transition>
  </div>
</template>

<script>
import BrickCollectionAdd from "../components/BrickCollectionAdd"
import BrickCollectionRow from "../components/BrickCollectionRow"
const SORT_NAME = 'name'
const SORT_MODEL = 'model'
const SORT_DATE = 'date'
const SORT_VALUE = 'vaule'

export default {
  name: "BrickCollection",
  components: {BrickCollectionAdd, BrickCollectionRow},
  data() {
    return {
      loaded: false,
      sortBy: SORT_NAME,
      direction: 1
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    brickCollection() {
      return this.$store.getters.brickCollection.slice().map(s => ({...s, title: s.title.replace(/^lego\s[\d]{0,5}/i, '')}))
    },
    totalCollections() {
      return this.$store.getters.totalCollections
    },
    totalValue() {
      return this.$store.getters.totalCollectionValue
    },
    collectionSorted() {
      switch(this.sortBy) {
        case SORT_DATE:
          return this.brickCollection.slice().sort((a,b) => a.title < b.title ? -1 : 1)
        case SORT_MODEL:
          return this.brickCollection.slice()
              .reduce((merged, set) => this.reduce(merged, set), [])
              .sort((a,b) => parseInt(a.model) > parseInt(b.model) ? this.direction * -1 : this.direction)
        case SORT_VALUE:
          return this.brickCollection.slice()
              .reduce((merged, set) => this.reduce(merged, set), [])
              .sort((a,b) => a.sum > b.sum ? this.direction * -1 : this.direction)
        case SORT_NAME:
        default:
          return this.brickCollection.slice()
              .reduce((merged, set) => this.reduce(merged, set), [])
              .sort((a,b) => this.direction > 0 ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title))
      }

    },
    currentSet() {
      return this.$store.getters.currentSet
    }
  },
  methods: {
    sortByModel() {
      this.sortBy = SORT_MODEL
      this.direction = this.direction * -1
    },
    sortByName() {
      this.sortBy = SORT_NAME
      this.direction = this.direction * -1
    },
    sortByValue() {
      this.sortBy = SORT_VALUE
      this.direction = this.direction * -1
    },
    reduce(merged, set) {
      {
        const mset = merged.find(s => s.upc === set.upc)
        if (mset) {
          merged = merged.map(s => {
            if (s.upc === mset.upc) {
              s.sum = parseFloat(s.sum) + parseFloat(set.value || 0)
              s.sets.push({id: set.id, condition: set.condition})
            }
            return s
          })
        } else {
          set.sets = [{id: set.id, condition: set.condition}]
          set.sum = parseFloat(set.value || 0)
          merged.push(set)
        }
        return merged
      }
    },
    setCurrentSet(set) {
      this.$store.commit('setCurrentSet', set)
    },
    onSave() {
      console.log('onSave')
      //this.loadCollections(true)
    },
    loadCollections(force) {
      if (!force && this.brickCollection && this.brickCollection.length) return this.loaded=true
      this.$store.dispatch('getBrickCollection')
          .then(() => {
            this.loaded = true
            this.$store.commit('setCurrentSet', null)
          })
          .catch(err => alert(err.message))
    }
  },
  mounted() {
    this.loadCollections(true)
  }
}
</script>
