<template>
  <div class="wrapper">
    <h1>Sign In</h1>
    <div class="mb-5 border-bottom">
      <div v-if="errorMessage" @click="errorMessage=''" class="alert alert-danger">
        {{ errorMessage }}
      </div>
      <form v-else @submit.prevent="login">
        <div class="mb-3">
          <input type="email" placeholder="Email" v-model="email" class="form-control" />
        </div>
        <div class="mb-3">
          <input type="password" placeholder="Password" v-model="password" class="form-control" />
        </div>
        <div class="mb-3">
          <button :disabled="disabled" class="btn btn-primary">Login</button>
        </div>
      </form>
    </div>
    <div class="d-flex flex-wrap justify-content-center">
      <router-link :to="{name:'SignUp'}" class="btn btn-link">Create Account</router-link>
      <router-link :to="{name:'PasswordReset'}" class="btn btn-link">Reset Password</router-link>
    </div>
  </div>
</template>
<script>
import firebase from "firebase"
//import db from "../../firestore"
export default {
  name: "Login",
  data(){
    return{
      email: '',
      password: '',
      successMessage: '',
      errorMessage: ''
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    disabled() {
      return !this.email || !this.password
    }
  },
  watch: {
    user(newValue) {
      if (newValue) this.$router.replace({name: 'BrickCollection'})
    }
  },
  methods: {
    login(){
      firebase.auth().signInWithEmailAndPassword(this.email, this.password)
          .then(user => {
            this.$store.commit('user', user);
          })
          .catch(err => {
            this.errorMessage = err.message
          });
    }
  }
}
</script>
