// 'https://api.barcodespider.com/v1/lookup?upc=UPC_NUMBER'

// With request header
// curl -X GET -H "token: YOUR_TOKEN" https://api.barcodespider.com/v1/lookup
// curl -X GET -H "token: YOUR_TOKEN" https://api.barcodespider.com/v1/search

// lego UPC example: 673419266895

const axios = require('axios');

const BARCODE_SPIDER_KEY = '50e4fd9e9d4dff39b6e0'
const BARCODE_LOOKUP_KEY = 'jnc4uiorh171poe5awqnwgb5pacrfa'
const UPCDB_KEY = 'A863DA9AEBA24D1FAA1A3911571CFD0E'


const PROVIDER_BARCODE_SPIDER = 'spider'
const PROVIDER_UPC_DB = 'upcdb'
const PROVIDER_BARCODE_LOOKUP = 'lookup'
const PROVIDER_UPC_ITEM_DB = 'upcitemdb'


const spiderResponse = {"item_response":{"code":200,"status":"OK","message":"Data returned"},"item_attributes":{"title":"LEGO Harry Potter Hogwarts Moment: Potions Class 76383 Brick-Built Playset with Professor Snape&rsquo;s Potions Class, New 2021 (270 Pieces)","upc":"673419339711","ean":"0673419339711","parent_category":"Toy","category":"Toy","brand":"LEGO","model":"6332775","mpn":"76383","manufacturer":"LEGO","publisher":"LEGO","asin":"B08HVZY72K","color":"Multicolor","size":"Standard","weight":"0.84 Pounds","image":"https://images.barcodespider.com/upcimage/673419339711.jpg","description":""},"stores":{"store_name":"Amazon US","title":"LEGO Harry Potter Hogwarts Moment: Potions Class 76383 Brick-Built Playset with Professor Snape&rsquo;s Potions Class, New 2021 (270 Pieces)","image":"https://m.media-amazon.com/images/I/51OSY1CyRUL._SL500_.jpg","price":"23.99","currency":"USD","store_link":"https://www.amazon.com/dp/B08HVZY72K/","updated":"2021-09-10 19:43:07"}}
//const spiderResponse = {"item_response":{"code":200,"status":"OK","message":"Data returned"},"item_attributes":{"title":"LEGO 760 London Bus","upc":"673419007603","ean":"0673419007603","parent_category":"Toy","category":"Toy","brand":"LEGO","model":"760","mpn":"","manufacturer":"LEGO","publisher":"LEGO","asin":"B004U0FD80","color":"","size":"","weight":"","image":"https://images.barcodespider.com/upcimage/673419007603.jpg","description":""},"Stores":[{"store_name":"Amazon US","title":"LEGO 760 London Bus","image":"https://m.media-amazon.com/images/I/519L6lI2JXL._SL500_.jpg","price":"89","currency":"USD","link":"https://www.barcodespider.com/getstore/NjczNDE5MDA3NjAzfEFtYXpvbiBVUw,,","updated":"2021-08-13 11:59:11"}]}
const upcitemdbResponse = {
  "code": "OK",
  "total": 1,
  "offset": 0,
  "items": [
    {
      "ean": "0673419266895",
      "title": "LEGO Seasonal Christmas Train Ride 40262 Building Set",
      "description": "LEGO Seasonal Christmas Train Ride 40262 Building Set:Includes 3 mini figures: a train driver, a passenger and a shopkeeperA red locomotive with 2 green carriages, a lamppost with a city map, 2 shop stalls, a holiday tree and various buildable accessories and elements, including cups, wreaths and a benchMeasures over 3 (8cm) high, 7 (19cm) wide and 6 (17cm) deepLEGO Christmas train ride has a fun, colorful design",
      "upc": "673419266895",
      "brand": "LEGO",
      "model": "40262",
      "color": "Multicolor",
      "size": "",
      "dimension": "",
      "weight": "",
      "category": "Toys & Games > Toys > Building Toys > Construction Set Toys",
      "currency": "CAD",
      "lowest_recorded_price": 9.91,
      "highest_recorded_price": 220,
      "images": [
        "https://i5.walmartimages.com/asr/ec562231-ebb6-49a5-9216-713a32af12c1_1.82d3c33030c8d909cf073ccdc910f663.jpeg?odnHeight=450&odnWidth=450&odnBg=ffffff",
        "https://www.lego.com/cdn/cs/set/assets/blt562b8cc4a3c24936/40262.jpg?format=jpg&fit=bounds&width=800&height=800&quality=80",
        "https://tshop.r10s.com/001/e1c/15d7/dd14/e0d1/6ded/33c8/1189e9b0150242ac110003.jpg?_ex=512x512"
      ],
      "offers": [
        {
          "merchant": "LEGO Brand Retail, Inc.",
          "domain": "LEGO Brand Retail, Inc.",
          "title": "LEGO Christmas Train Ride",
          "currency": "CAD",
          "list_price": "",
          "price": 11.99,
          "shipping": "",
          "condition": "New",
          "availability": "",
          "link": "https://www.upcitemdb.com/norob/alink/?id=z2p223v23323e4a4z2&tid=1&seq=1628858920&plt=2acd4932c67592fb11ab1b07250c5aca",
          "updated_t": 1608336213
        },
        {
          "merchant": "Rakuten(Buy.com)",
          "domain": "rakuten.com",
          "title": "LEGO Holiday 6175453 Christmas Train Ride 40262, Multi",
          "currency": "",
          "list_price": "",
          "price": 34.99,
          "shipping": "",
          "condition": "New",
          "availability": "",
          "link": "https://www.upcitemdb.com/norob/alink/?id=x2r253232323e4a4z2&tid=1&seq=1628858920&plt=8f75cc97de858fd55f09b9e831e5f2df",
          "updated_t": 1592145861
        },
        {
          "merchant": "Wal-Mart.com",
          "domain": "walmart.com",
          "title": "LEGO Seasonal Christmas Train Ride 40262 Building Set",
          "currency": "",
          "list_price": "",
          "price": 38.99,
          "shipping": "Free Shipping",
          "condition": "New",
          "availability": "Out of Stock",
          "link": "https://www.upcitemdb.com/norob/alink/?id=w2r233v23333c4d4v2&tid=1&seq=1628858920&plt=bb84cea26d9db3fb59fa11adb395ee4a",
          "updated_t": 1628550175
        },
        {
          "merchant": "Walmart Marketplace",
          "domain": "walmart.com",
          "title": "LEGO® Seasonal Christmas Train Ride 40262",
          "currency": "",
          "list_price": "",
          "price": 10.99,
          "shipping": "0",
          "condition": "New",
          "availability": "",
          "link": "https://www.upcitemdb.com/norob/alink/?id=w2p253y21333c4d4w2&tid=1&seq=1628858920&plt=69dce2ffaf61bb2ea748d109b3b49551",
          "updated_t": 1541385138
        }
      ],
      "elid": "264124601454"
    }
  ]
}
const upcdbResponse = {"added_time":"2017-11-28 05:18:06","modified_time":"2020-03-17 14:59:12","title":"LEGO Star Wars TM Millennium Falcon\u2122 75105","alias":"","description":"One of the most iconic starships from Star Wars is back and the LEGO Star Wars Millennium Falcon 75105 is tougher than ever before. It was featured in exciting new scenes from Star Wars: The Force Awakens. This latest LEGO version of the Millennium Falcon is crammed with new and updated external features. This includes an even more streamlined and detailed design. The detachable cockpit has space for two minifigures because no pilot should be alone. This kids' play set has dual spring-loaded shooters, sensor dish, ramp and an entrance hatch ideal for imaginary fun. Open the hull plates to reveal even more new and updated details inside, including the main hold with seating area and holochess board. Another fantastic update is the even more detailed hyperdrive for any pocket-sized pilot on a mission.","brand":"LEGO","manufacturer":"","mpn":"","msrp":"149.99","ASIN":"","category":"Toys/Building Sets & Blocks/Building Sets","metadata":{"age":"9 to 14 Years","msrp":"149.99","size":"","unit":"","color":"Multicolor","gender":"Unisex"},"stores":null,"barcode":"673419231619","success":true,"timestamp":1628866958,"images":null}
const lookupResponse = {
  "products": [
    {
      "barcode_number": "673419079822",
      "barcode_formats": "UPC-A 673419079822, EAN-13 0673419079822",
      "mpn": "4990068",
      "model": "",
      "asin": "",
      "title": "LEGO Knight's Kingdom Mistlands Tower Play Set",
      "category": "Toys & Games > Toys > Building Toys > Interlocking Blocks",
      "manufacturer": "Lego",
      "brand": "LEGO",
      "contributors": [],
      "age_group": "",
      "ingredients": "",
      "nutrition_facts": "",
      "energy_efficiency_class": "",
      "color": "",
      "gender": "",
      "material": "",
      "pattern": "",
      "format": "",
      "multipack": "",
      "size": "",
      "length": "",
      "width": "",
      "height": "",
      "weight": "",
      "release_date": "",
      "description": "Who will control the magic of the Mistlands Tower?",
      "features": [],
      "images": [
        "https://images.barcodelookup.com/3228/32282153-1.jpg"
      ],
      "last_update": "2021-06-22 07:12:45",
      "stores": [
        {
          "name": "Walmart",
          "country": "US",
          "currency": "USD",
          "currency_symbol": "$",
          "price": "262.49",
          "sale_price": "",
          "tax": [],
          "link": "https://www.walmart.com/ip/LEGO-Knight-s-Kingdom-Mistlands-Tower-Play-Set/4990068&intsrc=CATF_4309",
          "item_group_id": "",
          "availability": "",
          "condition": "",
          "shipping": [],
          "last_update": "2021-06-22 07:10:31"
        }
      ],
      "reviews": []
    }
  ]
}


const getTestResponse = (provider) => {
  switch (provider) {
    case PROVIDER_BARCODE_LOOKUP:
      return parseResponse(provider, lookupResponse)
    case PROVIDER_BARCODE_SPIDER:
      return parseResponse(provider, spiderResponse)
    case PROVIDER_UPC_DB:
      return parseResponse(provider, upcdbResponse)
    case PROVIDER_UPC_ITEM_DB:
      return parseResponse(provider, upcitemdbResponse)
    default:
      return null
  }
}

const lookup = async (upc, provider) => {
  upc = upc.trim()
  if (!upc || !(upc.length === 12 || upc.length === 13)) throw new Error('Invalid UPC/model')
  let url

  switch (provider) {
    case PROVIDER_BARCODE_LOOKUP:
      url = `https://api.barcodelookup.com/v3/products?barcode=${upc}&formatted=y&key=${BARCODE_LOOKUP_KEY}`
      break
    case PROVIDER_BARCODE_SPIDER:
      url = `https://api.barcodespider.com/v1/lookup?upc=${upc}&token=${BARCODE_SPIDER_KEY}`
      break
    case PROVIDER_UPC_DB:
      url = `https://api.upcdatabase.org/product/${upc}?apikey=${UPCDB_KEY}`
      break
    case PROVIDER_UPC_ITEM_DB:
      url = `https://api.upcitemdb.com/prod/trial/lookup?upc=${upc}`
      break
    default:break
  }

  if (!url) throw new Error('UPC PROVIDER undefined')

  try {
    const response = await axios.get(url);
    //const response = await fetch(url)
    // if (!response.ok) {
    //   throw new Error(response.statusMessage)
    // }
    // const json = await response.json()
    return parseResponse(provider, response.data)
  } catch (err) {
    console.log(err)
    if (err.response && err.response.data) {
      return parseResponse(provider, err.response.data)
    }
    throw new Error(err.message)
  }
}

const parseBarcodeLookup = (response) => {
  if (!response || !response.products) return null
  const [item] = response.products
  const [image] = item.images || []
  let [price] = ('stores' in item) ? item.stores.map(s => s.price || 0).sort((a, b) => a > b ? -1 : 1) : [0]
  return {
    upc: item.barcode_number || '',
    title: item.title || '',
    description: item.description || '',
    model: item.model || item.mpn || '',
    brand: item.brand || item.manufacturer || '',
    image: image,
    value: price
  }
}

const parseSpider = (response) => {
  if (!response || !response.item_attributes) return null
  const item = response.item_attributes
  let store
  let price
  try {
    if ('Stores' in response) {
      if (Array.isArray(response.Stores)) {
        [store] = response.Stores
      } else {
        store = response.Stores
      }
    }
    if (!store && 'stores' in response) {
      if (Array.isArray(response.stores)) {
        [store] = response.stores
      } else {
        store = response.stores
      }
    }
    if (!store && 'store' in response) {
      if (Array.isArray(response.store)) {
        [store] = response.store
      } else {
        store = response.store
      }
    }
    price = (store && 'price' in store) ? store.price : 0
  } catch (err) {
    console.log(response)
    console.log(err)
    price = 0
  }
  let model = item.model || ''
  if (model.length < 4 || model.length > 5) {
    model = (item.title.match(/\d{5}/) || [model])[0]
  }
  return {
    upc: item.upc || '',
    title: item.title || '',
    description: item.description || '',
    model: model,
    brand: item.brand || item.manufacturer || '',
    image: item.image,
    value: price
  }
}

const parseUpcDb = (response) => {
  if (!response || !response.barcode) return null
  const model = (response.npm || response.title || '').match(/\d{5}/)
  const [image] = response.images || []
  return {
    upc: response.barcode || '',
    title: response.title || '',
    description: response.description || '',
    model: model ? model[0] : '',
    brand: response.brand || response.manufacturer || '',
    image: image,
    value: response.msrp || 0
  }
}

const parseResponse = (provider, response) => {
  switch (provider) {
    case PROVIDER_BARCODE_LOOKUP:
      return parseBarcodeLookup(response)
    case PROVIDER_BARCODE_SPIDER:
      return parseSpider(response)
    case PROVIDER_UPC_DB:
      return parseUpcDb(response)
    default: return response
  }
}

export default {
  PROVIDER_BARCODE_SPIDER,
  PROVIDER_UPC_DB,
  PROVIDER_UPC_ITEM_DB,
  PROVIDER_BARCODE_LOOKUP,
  lookup,
  getTestResponse,
  parseResponse
}


