<template>

  <div v-if="user" class="card">
    <div v-if="error" @click="error=''" class="card-body bg-info text-white">
      {{ error }}
    </div>
    <div class="card-body pt-0 shadow">
      <transition name="fade">
      <div v-if="set">
        <div class="d-flex border-bottom bg-white py-3 mb-3 position-sticky" style="top:0">
          <button type="button" class="btn btn-light btn-outline-secondary ms-auto" @click="closeForm">Done</button>
        </div>

        <brick-set-details
            @onEdit="editSet"
            @addSet="saveSet"
            :set="set" :collections="collections"/>




      </div>


      <div v-else-if="scannerOn" class="pt-3">
        <scanner @on-cancel="scannerOn=false" @on-update="scannerUpdate" />
      </div>

      <div v-else-if="setNew.upc">
        <form @submit.prevent="savesetNew">
          <div class="d-flex py-3 mb-3 bg-white border-bottom position-sticky" style="top:0">
            <button type="button" @click="closeForm" class="btn btn-light me-3">Cancel</button>
            <button type="submit" :disabled="setNewDisabled" class="ms-auto btn btn-primary">Save</button>
          </div>

          <h3>Details for UPC: <small>{{ setNew.upc }}</small></h3>


          <label class="form-label">Set Title</label>
          <input type="text" v-model="setNew.title" class="form-control mb-3" />

          <div class="mb-3">
          <label class="form-label">Model #
            <span>
              <div v-if="rebuilding" class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Building...</span>
              </div>
              <button v-else-if="setNew.model" type="button" class="btn btn-sm btn-link" @click="getModel">rebrick from model</button>
            </span>
          </label>
          <input type="text" v-model="setNew.model" class="form-control" />

          </div>

          <label class="form-label">Brand</label>
          <select v-model="setNew.brand" class="form-control mb-3">
            <option value="LEGO">LEGO</option>
            <option value="OTHER">Other</option>
          </select>

          <label class="form-label">Description (optional)</label>
          <textarea v-model="setNew.description"  class="form-control mb-3"></textarea>

          <label class="form-label">Price / Current Value</label>
          <input v-model="setNew.value" placeholder="0.00" type="text" class="form-control mb-3" />


        </form>
      </div>

      <div v-else class="pt-3">
        <form @submit.prevent="upcLookup">
          <div class="input-group">
            <button class="btn btn-outline-primary" type="button" id="button-scan" @click="scannerOn=true">scan</button>
            <input type="text" v-model="upc" placeholder="UPC look up" class="form-control border-primary" aria-label="UPC look up" aria-describedby="button-lookup">
            <button class="btn btn-outline-primary" type="submit" id="button-lookup">Look Up</button>
          </div>
        </form>
      </div>
      </transition>
    </div>
  </div>

</template>
<script>

import Scanner from "./Scanner";
import BrickSetDetails from "./BrickSetDetails";

const EMPTY_SET = {
  upc: '',
  title: '',
  description: '',
  model: '',
  brand: 'LEGO',
  value: '',
  image: ''
}

export default {
  name: "BrickCollectionAdd",
  components: {BrickSetDetails, Scanner},
  data() {
    return {
      error: '',
      upcResponse: null,
      scannerOn: false,
      rebuilding: false,
      upc: '',
      setNew: { ...EMPTY_SET },
      set: null,
      conditions: [],
      collections: [],
    }
  },
  computed: {
    currentSet() {
      return this.$store.getters.currentSet
    },
    disabled() {
      return !this.set.upc || !this.set.title
    },
    user() {
      return this.$store.getters.user
    },
    setNewDisabled() {
      return !this.setNew || !this.setNew.upc || !this.setNew.title || !this.setNew.model || !this.setNew.brand || this.setNew.value !== parseFloat(this.setNew.value).toFixed(2).toString()
    }
  },
  methods: {
    editSet() {
      if (!this.set) return
      this.setNew = { ...this.set }
      this.set = null
    },
    async upcLookup() {
      if (!this.upc) return

      this.$store.dispatch('upcLookup', this.upc)
        .then(data => {
          if (data.error) {
            return this.error = data.error
          }
          return this.upcResponse = data
        })
        .catch(err => {
          this.error = err.message
          if (
              (this.upc.trim().length === 12 || this.upc.trim().length === 13)
              && !this.upcResponse) {
            return this.setNew.upc = this.upc
          }
          this.upc = ''
        })
    },

    savesetNew() {
      if (this.setNewDisabled) return
      this.$store.dispatch('saveSet', this.setNew)
        .then( () => {
          this.error = ''
          this.setNew = { ...EMPTY_SET }
          this.upcLookup()
        })
    },

    closeForm() {
      this.error = ''
      this.upc = ''
      this.upcResponse = null
      this.setNew = { ...EMPTY_SET }
      this.$store.commit('setCurrentSet', null)
    },

    scannerUpdate(upc) {
      this.error = ''
      this.upc = upc
      this.scannerOn = false
      this.upcLookup()
    },

    getCollections() {
      if (!this.set || !this.upc) {
        return this.collections = []
      }

      this.$store.dispatch('getCollectionsByUpc', this.upc)
      .then(collections => this.collections = collections)
      .catch(err => alert(err.message))
    },

    getModel() {
      this.rebuilding = true;
      this.$store.dispatch('getSetFromModel', this.setNew.model)
      .then(data => {
        if (data) {
          this.setNew = {...this.setNew, ...data}
        }
        this.rebuilding = false
      })
      .catch(err => {
        this.error = err.message
        this.rebuilding = false
      })
    },

    async saveSet(condition) {
      this.$store.dispatch('addUserSet', { ...this.set, condition: condition})
      .then(() => {
        this.error = ''
        //this.closeForm()
      })
      .catch(err => {
        this.error = err.message
        //alert(err.message)
      })
    }
  },
  watch: {
    currentSet(newVal) {
      this.set = newVal
      this.upc = (newVal && newVal.upc) || ''
      this.scannerOn = false
      this.getCollections()
    },
    upcResponse(newVal) {
      if (
          !newVal
          || !newVal.title
          || !newVal.upc
      ) {
        this.$store.commit('setCurrentSet', null)
        return
      }
      this.error = ''
      const set = {
        upc: newVal.upc,
        title: newVal.title,
        brand: newVal.brand || '',
        model: newVal.model || '',
        description: newVal.description || '',
        quantity: newVal.quantity || 0,
        image: newVal.image || '',
        value: newVal.value || 0
      }
      this.$store.commit('setCurrentSet', set)
    }
  }
}
</script>
