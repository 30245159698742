<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <span class="small">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Scanning...</span>
        </div>
        {{ lastResult }}</span>
      <button type="button" @click="$emit('onCancel')" class="ms-auto btn btn-outline-secondary">cancel</button>
    </div>
    <div ref="video-container"></div>
  </div>
</template>
<script>

import Quagga from "@ericblade/quagga2"

export default {
  name: "Scanner",
  emits: ['onCancel', 'onUpdate'],
  data() {
    return {
      video: null,
      canvas: null,
      lastResult: null
    }
  },
  methods: {
    initCamera(constraints, callback) {
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia(constraints)
            .then((stream) => {
              this.video.srcObject = stream;
              this.video.play();
              setTimeout(callback, 3000)
            })
            .catch(err => alert(err.message))
      }

    },

    copyToCanvas(video, ctx) {
      ( function frame() {
        ctx.drawImage(video, 0, 0);
        window.requestAnimationFrame(frame);
      }());
    },

    loadCamera() {
      const constraints = {
        video: {
          mandatory: {
            minWidth: 1280,
            minHeight: 720
          }
        }
      }

      this.video = document.createElement('video')
      this.video.classList.add('img-fluid')
      this.$refs["video-container"].appendChild(this.video)
      this.canvas = document.createElement('canvas')
      this.$refs["video-container"].appendChild(this.canvas)

      this.initCamera(constraints, () => {
        console.log(this.video.videoWidth, this.video.videoHeight)
        // this.canvas.setAttribute('width', this.video.videoWidth);
        // this.canvas.setAttribute('height', this.video.videoHeight);
        // this.copyToCanvas(this.video, this.canvas.getContext('2d'));
        // this.video.classList.add('d-none')

        Quagga.init({
          inputStream : {
            name : "Live",
            type : "LiveStream",
            target: this.video
          },
          locate: true,
          decoder : {
            readers : ["upc_reader"]
          },
          debug: {
            drawBoundingBox: false,
            showFrequency: false,
            drawScanline: false,
            showPattern: false
          }
        }, (err) => {
          if (err) {
            console.log(err);
            return
          }
          console.log("Initialization finished. Ready to start");
          Quagga.start();
        });

        Quagga.onProcessed(result => {
          // const drawingCtx = Quagga.canvas.ctx.overlay
          // const drawingCanvas = Quagga.canvas.dom.overlay;

          if (result) {
            if (result.boxes) {
              console.log(result.boxes)
              // drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
              // result.boxes.filter(box => box !== result.box)
              //     .forEach(box => {
              //       Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: "green", lineWidth: 2});
              //     });
            }

            // if (result.box) {
            //   Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: "#00F", lineWidth: 2});
            // }
            //
            // if (result.codeResult && result.codeResult.code) {
            //   Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'red', lineWidth: 3});
            // }
          }
        });

        Quagga.onDetected(result => {
          console.log(result)
          const code = result.codeResult.code

          if (this.lastResult !== code) {
            this.lastResult = code;
            this.$emit('onUpdate', this.lastResult)
          }
        });

      });

    },

    stopScan() {
      Quagga.stop();

      if (!this.video) return

      const stream = this.video.srcObject;
      if (!stream) return

      const tracks = stream.getTracks();
      if (!tracks) return

      tracks.forEach(track => {
        track.stop()
      })

      this.video.srcObject = null;

      if (this.canvas) this.canvas.remove()
      this.video.remove()
    }
  },
  mounted() {
    this.loadCamera()
  },
  beforeUnmount() {
    this.stopScan()
  }
}
</script>
