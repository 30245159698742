import firebase from 'firebase'
import 'firebase/firestore'
const firebaseConfig = {
  apiKey: "AIzaSyAkLWFsIEHvX5rK88EsingUP7uPGJPOmU8",
  authDomain: "brickbase-2b15f.firebaseapp.com",
  projectId: "brickbase-2b15f",
  storageBucket: "brickbase-2b15f.appspot.com",
  messagingSenderId: "1077087225961",
  appId: "1:1077087225961:web:2b665109c55da2f5f2faf8",
  measurementId: "G-DJLPDSNX7D"
};
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
export default db;
