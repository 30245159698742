import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase'
import {setConditions} from './common/globals'
import "./assets/scss/style.scss"
import "bootstrap";

let app;
firebase.auth().onAuthStateChanged(user => {
  store.commit('user', user)
  if(!app){
    app = createApp(App).use(store).use(router)
    app.config.globalProperties.$setConditions = setConditions
    app.mount('#app')
  }
});

