<template>
  <div class="wrapper">
    <h1>Dashboard</h1>
    <div v-if="error" class="alert alert-info">{{ error }}</div>
    <div class="my-3 sticky-top">
      <brick-collection-add />
    </div>
    <div v-if="loaded" class="d-flex flex-wrap justify-content-center">
      <div class="card bg-primary text-white m-2 card-dash pointer"
           @click="$router.push({'name':'BrickCollection'})">
        <div class="card-body d-flex flex-column align-items-center justify-content-center">
          <div class="display-1">{{sets }}</div>
          <div class="small">SETS</div>
        </div>
      </div>
      <div class="card bg-danger text-white m-2 card-dash pointer"
           @click="$router.push({'name':'BrickCollection'})">
        <div class="card-body d-flex flex-column align-items-center justify-content-center">
          <div class="display-2">${{ parseInt(totalValue) }}</div>
          <div class="small">MSRP Value</div>
        </div>
      </div>
      <div class="card bg-warning m-2 card-dash pointer"
           @click="setCurrent">
        <div v-if="mostValuable" class="card-body d-flex flex-column align-items-center justify-content-center">
          <div class="display-3">${{ mostValuable.value }}</div>
          <div class="text-center mb-2" :title="mostValuable.title">
            <img :src="mostValuable.image" :alt="mostValuable.title" class="w-50 shadow rounded"/>
          </div>
          <small>MOST VALUABLE</small>
        </div>
      </div>
      <div class="card bg-success text-white m-2 card-dash">
        <div class="card-body"></div>
      </div>
    </div>
  </div>
</template>

<script>
import BrickCollectionAdd from "../components/BrickCollectionAdd";
export default {
  name: "Dashboard",
  components: {BrickCollectionAdd},
  data: () => ({
    loaded: false,
    error: null
  }),
  computed: {
    brickCollection() {
      return this.$store.getters.brickCollection
    },
    sets() {
      if (!this.loadCollection) return 0
      return this.brickCollection.length
    },
    mostValuable() {
      if (!this.brickCollection) return
      return this.brickCollection.slice().sort((a, b) => a.value > b.value ? 1 : -1)[0]
    },
    totalValue() {
      return this.$store.getters.totalCollectionValue
    }
  },
  methods: {
    setCurrent() {
      this.$store.commit('setCurrentSet', this.mostValuable)
    },
    loadCollection() {
      if (this.brickCollection.length > 0) return this.loaded=true
      this.loaded = false
      this.$store.dispatch('getBrickCollection')
          .then(() => {
            this.loaded = true
            this.$store.commit('setCurrentSet', null)
          })
          .catch(err => alert(err.message))
    }
  },
  mounted() {
    this.loadCollection()
  }
}
</script>

<style lang="scss">
.card-dash {width:300px; height: 300px;}
</style>
