const axios = require('axios');
const KEY = 'bcc0a6e0f58d1316c4db6d64a21a8078'

const axiosInstance = axios.create({
  baseURL: 'https://rebrickable.com/api/v3/lego'
});
axiosInstance.defaults.headers.common['Authorization'] = `key ${KEY}`;

export const getTheme = async (id, name) => {
  try {
    const response = await axiosInstance.get(`/themes/${id}`)
    const newName = `${response.data.name} ${name || ''}`.trim()
    if (response.data.parent_id > 0) {
      return await getTheme(response.data.parent_id, newName)
    }
    return {...response.data, name: newName}
  } catch (err) {
    throw new Error(err.message)
  }
}

export const getSet = async (id) => {
  try {
    const response = await axiosInstance.get(`/sets/${id}-1`)
    return await parseRebrickable(response.data)
  } catch (err) {
    throw new Error(err.message)
  }
}

export const parseRebrickable = async response => {
  if (!response || !response.set_num) return null
  const model = response.set_num.split('-')[0]
  let theme = ''
  try {
    const themeResponse = await getTheme(response.theme_id)
    theme = themeResponse.name + ': ' || ''
  } catch (err) {
    console.log(err)
  }
  return {
    model: model,
    title: `${theme}${response.name}`,
    description: `${theme} : Year: ${response.year} : ${response.num_parts} pcs/pzs : more at: ${response.set_url}`,
    image: response.set_img_url,
    brand: 'LEGO'
  }
}
