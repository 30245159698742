<template>
  <div class="wrapper" v-if="user">
    <h1>Profile</h1>
    <div class="d-md-flex mb-5">
      <div class="card mb-3 mb-md-0 me-md-3 flex-fill">
        <div class="card-body">
          <dl>
            <dt>Email</dt>
            <dd>{{ user.email }}</dd>
            <dt>Username</dt>
            <dd>{{ user.displayName }}</dd>
          </dl>
        </div>
      </div>

      <div class="card flex-fill">
        <div class="card-body">
          <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
          <div v-else-if="loaded">
            <dl v-if="edit">
              <user-data-form :user="userData" @onCancel="edit=false" @onSave="saveUserData" />
            </dl>
            <div v-else>
              <dt>First Name</dt>
              <dd>{{ userData.firstName }}</dd>
              <dt>Last Name</dt>
              <dd>{{ userData.lastName }}</dd>
              <button type="button" class="btn btn-link" @click="edit=true">edit</button>
            </div>
          </div>
          <div v-else class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading data...</span>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-5">
      <button class="btn btn-light" @click.prevent="logout">Logout</button>
    </div>

<!--    <pre>{{ user }}</pre>-->

  </div>
</template>
<script>
import db from '../common/firestore'
import UserDataForm from "../components/UserDataForm";
import firebase from "firebase";
export default {
  name: "Profile",
  components: {UserDataForm},
  data() {
    return {
      userData: {firstName:'',lastName:''},
      loaded: false,
      errorMessage: null,
      edit: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    }
  },
  methods: {
    logout(){
      firebase.auth().signOut().then(() => {
        this.$router.replace({name:"Home"});
      });
    },
    saveUserData(data) {
      this.loaded = false
      db.collection('users').doc(this.user.uid).set({
        firstName: data.firstName ?? '',
        lastName: data.lastName ?? '',
        email: this.user.email,
        username: this.user.displayName || ''
      }).then(() => {
        this.loadUserData()
      })
    },
    loadUserData() {
      db.collection('users').doc(this.user.uid).get()
          .then((doc) => {
            if (doc.exists) {
              this.userData = doc.data()
              this.edit = false
            } else {
              this.edit = true
            }
            this.loaded = true
          }).catch((error) => {
            this.errorMessage = error
        //console.log("Error getting document:", error);
      });
    }
  },
  mounted() {
    this.loadUserData()
  }
}
</script>
