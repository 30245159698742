<template>

      <form @submit.prevent="saveData">
        <div class="mb-3">
          <label class="form-label">First Name</label>
          <input type="text" v-model="userData.firstName" class="form-control" />
        </div>
        <div class="mb-3">
          <label class="form-label">Last Name</label>
          <input type="text" v-model="userData.lastName" class="form-control" />
        </div>
        <div class="d-grid gap-2 d-md-block">
          <button type="submit" class="btn btn-primary me-md-2" :disabled="disabled">Save</button>
          <button type="button" class="btn btn-light" @click="$emit('onCancel')">Cancel</button>
        </div>
      </form>
</template>
<script>
export default {
  name: "UserDataForm",
  props: ['user'],
  data() {
    return {
      userData: {
        firstName: '',
        lastName: '',
      }
    }
  },
  computed: {
    disabled() {
      return !this.userData.firstName || !this.userData.lastName
    }
  },
  methods: {
    saveData() {
      if (this.disabled) return
      this.$emit('onSave', this.userData)
    },
    loadUserData() {
      const user = this.user
      if (!user) return
      if (user.firstName) this.userData.firstName = user.firstName
      if (user.lastName) this.userData.lastName = user.lastName
    }
  },
  mounted() {
    this.loadUserData()
  }
}
</script>
