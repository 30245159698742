<template>
  <nav class="navbar navbar-expand navbar-light bg-light mb-3 mb-md-5 border-bottom">
    <div class="container">

      <router-link :to="{name: 'Home'}" class="nav-item text-decoration-none text-black d-inline-flex align-items-center">
        <div class="d-inline-flex shadow-sm align-items-center p-0 mb-0" style="height:15px">
          <div v-for="bg in ['bg-primary', 'bg-danger', 'bg-warning', 'bg-success']" :key="bg"
               class="h-100" :class="bg" style="width:15px;"></div>
        </div>
        <strong class="mx-3 d-none d-md-inline-block">BrickBase App</strong>
      </router-link>

      <div class="ms-auto">
        <ul v-if="user" class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link"  :to="{name: 'Profile'}">{{ user.email }}</router-link>
          </li>
        </ul>

        <ul v-if="!user" class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link :to="{name:'Login'}" class="nav-link">Sign In</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name:" Header-component",
  computed: {
    user() {
      return this.$store.getters.user
    }
  }
}
</script>
