<template>
  <div class="d-md-flex">
    <div class="me-3">
      <img :src="set.image"
           class="mb-3"
           style="width:200px;  background-color: #abc;" />
    </div>
    <div>
      <h2>{{ set.title }}</h2>
      <div class="d-flex flex-wrap align-items-center">
        <div class="badge bg-secondary m-1">
          <strong>brand: </strong> <span class="font-monospace">{{ set.brand }}</span>
        </div>
        <div class="badge bg-secondary m-1">
          <strong>UPC: </strong> <span class="font-monospace">{{ set.upc }}</span>
        </div>
        <div class="badge bg-secondary m-1">
          <strong>model: </strong> <span class="font-monospace">{{ set.model }}</span>
        </div>
      </div>
      <div class="mb-3">
        <strong>Value: </strong> ${{ parseFloat(set.value).toFixed(2) }}
        <button class="btn btn-link" @click="$emit('onEdit')" type="button">edit</button>
      </div>
      <div class="small mb-3">{{ set.description }}</div>
      <hr>
      <h3>In Your Collection:</h3>
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Key</th>
            <th>Condition</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr :key="collection.id" v-for="collection in collections">
            <td class="small">{{ collection.id }}</td>
            <td>{{ conditionName(collection.condition) }}</td>
            <td class="text-end">
              <button type="button" @click="removeItem(collection.id)" class="ms-auto btn btn-sm btn-outline-secondary">&times;</button>
            </td>
          </tr>
          <tr>
            <td colspan="3" class="text-end">

              <!-- Example split danger button -->
              <div class="btn-group">
                <button type="button" class="btn btn-primary" @click="$emit('addSet', $setConditions[0].val)">
                  Add New Box</button>
                <button type="button"
                        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                        id="dd-add-condition"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                  <span class="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dd-add-condition">
                  <li v-for="condition in $setConditions" :key="condition.id">
                    <a href="#" class="dropdown-item" @click.prevent="$emit('addSet', condition.val)">
                      {{ condition.label }}
                    </a>
                  </li>
                </ul>
              </div>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BrickSetDetails',
  props: ['set'],
  emits: ['onEdit','didRemove','addSet'],
  computed: {
    collections() {
      return this.$store.getters.brickCollection.slice().filter(c => c.upc === this.set.upc)
    }
  },
  methods: {
    conditionName(condition) {
      const [{label}] = this.$setConditions.filter(c => c.val === condition)
      return label
    },
    removeItem(id) {
      this.$store.dispatch('removeUserSet', id)
          .then(() => {
            this.$emit('didRemove', this.set.id)
          })
          .catch(err => alert(err.message))
    }
  }
}
</script>
