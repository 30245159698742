<template>
  <div class="ms-auto me-auto" style="width:100%; max-width: 500px">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "AuthContainer"
}
</script>
