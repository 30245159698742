import { createRouter, createWebHistory } from 'vue-router'
import firebase from "firebase"
import Home from '../views/Home'
import Dashboard from "../views/Dashboard"
import AuthContainer from "../views/auth/AuthContainer";
import Login from '../views/auth/Login'
import SignUp from '../views/auth/SignUp'
import PasswordReset from '../views/auth/PasswordReset'
import Profile from '../views/Profile'
import BrickCollection from "../views/BrickCollection"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/collection',
    name: 'BrickCollection',
    component: BrickCollection,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthContainer,
    redirect: '/auth/login',
    meta: {
      requiresNoAuth: true
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        meta: {
          requiresNoAuth: true
        },
        component: Login
      },
      {
        path: 'signup',
        name: 'SignUp',
        meta: {
          requiresNoAuth: true
        },
        component: SignUp
      },
      {
        path: 'reset-password',
        name: 'PasswordReset',
        meta: {
          requiresNoAuth: true
        },
        component: PasswordReset
      }
    ]
  }

]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresNoAuth = to.matched.some(record => record.meta.requiresNoAuth)

  if (requiresAuth && !currentUser) return next({name:'Login'})
  if (requiresNoAuth && currentUser) return next({name:'Profile'})
  if (!requiresAuth && currentUser) return next({name:'BrickCollection'})

  next();
});

export default router
