<template>
  <div class="wrapper">
    <h1>Create a BrickBase App Account</h1>

    <div class="mb-5 border-bottom">
      <div v-if="successMessage" class="alert alert-success">{{ successMessage }}</div>
      <div v-else-if="errorMessage" @click="errorMessage=''" class="alert alert-danger">{{ errorMessage }}</div>
      <form v-else @submit.prevent="registerUser">
        <div class="mb-3">
          <input type="text" placeholder="Firstname" v-model="userData.firstname" class="form-control" />
        </div>
        <div class="mb-3">
          <input type="text" placeholder="Lastname" v-model="userData.lastname" class="form-control" />
        </div>
        <div class="mb-3">
          <input type="text" placeholder="Username" v-model="userData.username" class="form-control" />
        </div>
        <div class="mb-3">
          <input type="email" placeholder="Email" v-model="userData.email" class="form-control" />
        </div>
        <div class="mb-3">
          <input type="password" placeholder="Choose a password" v-model="userData.password" class="form-control" />
        </div>
        <div class="mb-3">
          <button class="btn btn-primary" :disabled="disabled">Create account</button>
        </div>
      </form>
    </div>

    <div class="d-flex flex-wrap justify-content-center">
      <router-link :to="{name:'Login'}" class="btn btn-link">Already have a account?</router-link>
    </div>

  </div>
</template>
<script>
import firebase from 'firebase'
import db from '../../common/firestore'
export default {
  name: "SignUp",
  data(){
    return{
      userData: {
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        password: ''
      },
      successMessage: '',
      errorMessage: ''
    }
  },
  computed:{
    disabled(){
      return !this.userData.firstname || !this.userData.lastname || !this.userData.username ||
          !this.userData.email || !this.userData.password
    }
  },
  methods:{
    registerUser(){
      firebase.auth().createUserWithEmailAndPassword(this.userData.email, this.userData.password)
          .then(() => {
            firebase.auth().currentUser.updateProfile({
              displayName: this.userData.username
            }).then(user => {
              db.collection('users').doc(user.uid).set({
                firstname: this.userData.firstname,
                lastname: this.userData.lastname,
                username: this.userData.username,
                email: this.userData.email,
              }).then(() => {
                this.$router.replace({name:"Profile"});
              }).catch(err => {
                this.errorMessage = err.message;
              });
            }).catch(err => {
              this.errorMessage = err.message;
            });
          }).catch(err => {
        this.errorMessage = err.message
      });
    }
  }
}
</script>
