import { createStore } from 'vuex'
import upcApi from "../common/upc"
import {getSet, getTheme} from "../common/rebrickable"
import firestore from "../common/firestore"

export default createStore({
  state: {
    user: null,
    brickCollection: [],
    currentSet: null,
  },
  getters: {
    user(state) {
      return state.user
    },
    brickCollection(state) {
      return state.brickCollection || []
    },
    currentSet(state) {
      return state.currentSet
    },
    totalCollections(state) {
      if (!state.brickCollection) return 0
      return state.brickCollection.length || 0
    },
    totalCollectionValue(state) {
      if (!state.brickCollection) return 0
      return state.brickCollection.slice().reduce((a, b) => parseFloat(a) + parseFloat(b.value), 0)
        .toFixed(2)
    }
  },
  mutations: {
    user(state, user) {
      state.user = user
    },
    brickCollection(state, docs) {
      state.brickCollection = docs
    },
    updateBrickCollection(state, doc) {
      state.brickCollection = state.brickCollection.slice().map(c => {
        if (c.id === doc.id) {
          return doc
        }
        return c
      })
    },
    addToBrickCollection(state, doc) {
      state.brickCollection = [doc, ...state.brickCollection.filter(s => s.id !== doc.id)]
    },
    removeSetFromBrickCollection(state, id) {
      state.brickCollection = state.brickCollection.filter(s => s.id !== id)
    },
    setCurrentSet(state, set) {
      state.currentSet = set
    }
  },
  actions: {
    getBrickCollection(context) {
      context.commit('brickCollection', [])

      const BrickCollection = firestore.collection('users')
        .doc(context.getters.user.uid)
        .collection('brick_collection')
      BrickCollection.get()
        .then(querySnapshot => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            context.commit('addToBrickCollection', {...doc.data(), id: doc.id})
          });
          return Promise.resolve()
        }).catch(err => {
          return Promise.reject(err)
      })
    },
    async getCollectionsByUpc(context, upc) {
      if (!upc) return Promise.reject( new Error('no UPC defined') )

      let items = []
      try {
        const colRef = firestore.collection('users')
          .doc(context.getters.user.uid)
          .collection('brick_collection')
        const snapshot = await colRef.where("upc", "==", upc).get();
        snapshot.forEach(doc => {
          items.push({...doc.data(), id: doc.id})
        })
        return Promise.resolve(items)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async addItemForUpc(context, [upc, condition]) {
      try {
        const docRef = await firestore.collection('users').doc(context.getters.user.uid)
          .collection('brick_collection').doc(upc)
          .collection('items').add({condition: condition})
        return Promise.resolve({id: docRef.id, 'condition': condition})
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async deleteItemForUpc(context, [upc, id]) {
      try {
        await firestore.collection('users').doc(context.getters.user.uid)
          .collection('brick_collection').doc(upc)
          .collection('items').doc(id).delete()
        return Promise.resolve()
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async upcLookup(context, upc) {
      const errors = [];
      upc = upc.trim()

      // guard upc - if not long enough, then save lookup

      if (upc === upcApi.PROVIDER_BARCODE_LOOKUP) {
        return Promise.resolve(upcApi.getTestResponse(upcApi.PROVIDER_BARCODE_LOOKUP))
      }
      if (upc === upcApi.PROVIDER_BARCODE_SPIDER) {
        return Promise.resolve(upcApi.getTestResponse(upcApi.PROVIDER_BARCODE_SPIDER))
      }
      if (upc === upcApi.PROVIDER_UPC_DB) {
        return Promise.resolve(upcApi.getTestResponse(upcApi.PROVIDER_UPC_DB))
      }

      if (!upc || !(upc.length === 12 || upc.length === 13)) {
        return Promise.reject(new Error('Valid UPC required'))
      }

      // guard user - only for users
      const user = context.getters.user
      if (!user) return Promise.reject(new Error('Authorization required'))

      // check user db first - avoid api call
      // not gonna be here because doc.id is not upc anymore -- it's a query
      // try {
      //   const doc = await firestore.collection('users')
      //     .doc(user.uid).collection('brick_collection')
      //     .doc(upc).get()
      //   if (doc.exists) return Promise.resolve(doc.data())
      //   errors.push('not in firestore user brick_collection')
      // } catch (err) {
      //   errors.push(err.message)
      //   console.error(err)
      // }

      // next check global DB
      try {
        const doc = await firestore.collection('sets')
          .doc(upc).get()
        if (doc.exists) return Promise.resolve(doc.data())
        errors.push('not in firestore sets')
      } catch (err) {
        errors.push(err.message)
        console.error(err)
      }

      // check upcdb
      // -- not cors compatible, so don't do
      // try {
      //   const upcdbSet = await upcApi.lookup(upc, upcApi.PROVIDER_UPC_DB)
      //   if (upcdbSet) return Promise.resolve(upcdbSet)
      //   errors.push('not in upc_db')
      // } catch (err) {
      //   errors.push(err.message)
      //   console.error(err)
      // }

      // check barcodelookup
      // -- not cors compatible, so don't do
      // try {
      //   const lookupSet = await upcApi.lookup(upc, upcApi.PROVIDER_BARCODE_LOOKUP)
      //   if (lookupSet) return Promise.resolve(lookupSet)
      //   errors.push('not in barcodeLookup')
      // } catch (err) {
      //   errors.push(err.message)
      //   console.error(err)
      // }

      // check barcodeSpider
      try {
        const spiderSet = await upcApi.lookup(upc, upcApi.PROVIDER_BARCODE_SPIDER)
        if (spiderSet) {
          await context.dispatch('saveSet', spiderSet)
          return Promise.resolve(spiderSet)
        }
        errors.push('not in barcodeSpider')
      } catch (err) {
        errors.push(err.message)
        // if (err.code === 404) {
        //   return Promise.reject(new Error('No Products found. ' + errors.join(' AND ')))
        // }
        // but don't return reject because lots of 429 errors after successful
      }

      return Promise.reject(new Error('No Products found. ' + errors.join(' AND ')))
      //return Promise.resolve({error: errors})
      //873419115128

    },

    /**
     * Adds Set with condition regardless if already exists
     * @param context
     * @param set
     * @returns {Promise<unknown>}
     */
    async addUserSet(context, set) {
      set = {...set}
      if (!set || !set.upc) return Promise.reject(new Error('missing upc'))

      try {
        const colRef = firestore.collection('users')
          .doc(context.getters.user.uid)
          .collection('brick_collection')
        const docRef = await colRef.add(set)
        context.commit('addToBrickCollection', {...set, id: docRef.id})
        return Promise.resolve()
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async removeUserSet(context, id) {
      try {
        const docRef = firestore.collection('users')
          .doc(context.getters.user.uid)
          .collection('brick_collection')
          .doc(id)
        await docRef.delete()
        context.commit('removeSetFromBrickCollection', id)
        return Promise.resolve()
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async saveSet(context, set) {
      set = {...set}
      if (!set || !set.upc) return Promise.reject(new Error('missing upc'))

      try {
        const docRef = firestore.collection('sets').doc(set.upc)
        const doc = await docRef.get()

        if (doc.exists) {
          await docRef.update(set)
        } else {
          await docRef.set(set)
        }

        const snapshot = await firestore
          .collection('users')
          .doc(context.getters.user.uid)
          .collection('brick_collection')
          .where('upc', '==', set.upc)
          .get()

        snapshot.forEach(doc => {
          let itemSet = doc.data()
          itemSet = { ...itemSet, ...set }
          doc.ref.update(itemSet);
          context.commit('updateBrickCollection', {...itemSet, id: doc.id})
        })

        return Promise.resolve()
      } catch (err) {
        return Promise.resolve(err)
      }
    },

    async updateSetCount(context, [upc, quantity]) {
      try {
        await firestore.collection('users').doc(context.getters.user.uid)
          .collection('brick_collection').doc(upc).update({
            quantity: quantity
          })
        return Promise.resolve()
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async getSetFromModel(context, id) {
      try {
        const set = await getSet(id)
        return Promise.resolve(set)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async getThemeFromId(context, id) {
      try {
        const theme = await getTheme(id)
        return Promise.resolve(theme)
      } catch (err) {
        return Promise.reject(err)
      }
    }
  },
  modules: {
  }
})
