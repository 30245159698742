<template>
  <div class="wrapper">
    <div class="p-5 text-white bg-dark rounded-3">
      <h1 class="display-4">Welcome to BrickBase App</h1>
      <p class="lead">What Logo™ sets do you own?  What are they worth?</p>
      <p class="lead">Now easily organize and store your Lego™ sets collection in the BrickBase App database!
        Simply take a picture of the barcode on your box with your device; BrickBase look-up names, pictures
        and values; and create a personalized databse of your collection that is
        accessible from any device anywhere.</p>
      <hr>
      <h3>Sign up with a free BrickBase account: </h3>
      <router-link :to="{name:'SignUp'}" class="btn btn-lg btn-outline-light">Get Started!</router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home'
}
</script>
